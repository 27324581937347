export const LIST_OF_PATHS_TO_HIDE_DEFAULT_HEADER_AND_FOOTER = [
  '/account/purchase',
  '/lifetime-promo-live',
  '/lifetime-promo-playboy',
  '/nn-promo-card',
  '/nnl-promo-card',
  '/affiliates/signup',
  '/affiliates/login',
  '/affiliates/password/reset',
  '/affiliates/password/set',
];

export const NAKED_NEWS_LIVE_ID = 241;

export const PAST_ANCHOR_PAGES_WITH_NEW_DESIGN = [58, 113, 246, 14925, 108, 115, 96, 98, 109, 73, 111, 106, 61, 66, 81, 82, 83, 95, 91, 93];

export const archivesTitle = {
  segment: 'Segment Archive',
  anchor: 'Anchor Archive',
  date: 'Naked News Archives',
  vintage: 'Vintage Naked News',
  all: 'Latest Naked News Shows',
  search: 'Search Archives',
};
