import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import InternalHeaderLinks from '../InternalHeaderLinks/InternalHeaderLinks';
import { isNudeDomain } from '../../../utils/helpers';
import CountdownTimerComponent from '../../generalComponents/CountdownTimerComponent/CountdownTimerComponent';
import LoginModalStore from '../../../store/LoginModalStore';
import { AFFILIATE_LOGIN_URL } from '../../../constants/AffiliatePageText';
import AffiliateSubscriptionModal from '../../generalComponents/AffiliateSubscriptionModal';

import Unauth from './Unauth/Unauth';
import Auth from './Auth/Auth';


const LoginModal = React.lazy(() => import('../../generalComponents/LoginModal'));


@observer
class MobileHeaderNav extends React.Component {
  constructor(...args) {
    super(...args);
    this.wrapperRef = React.createRef();
  }
  state = { showAffiliateLoginModal: false };

  getAffiliateLoginUrl = () => {
    const { user } = this.props;
    const hasOffersAffiliateProgramIsEnabled = user?.features?.includes('USE_HAS_OFFERS_AFFILIATE_PROGRAM_LINKS');
    if (hasOffersAffiliateProgramIsEnabled) {
      return '/affiliates/login';
    }

    return AFFILIATE_LOGIN_URL;
  }

  openLoginModal = (e) => {
    const { isAffiliatePage } = this.props;
    e.preventDefault();
    if (isAffiliatePage) {
      window.location.href = this.getAffiliateLoginUrl();
    } else {
      LoginModalStore.setShouldShowModal(true);
    }

  };

  closeModal = (user) => {
    LoginModalStore.setShouldShowModal(false);
    if (user) {
      if (user.userDetails.account.anchor) {
        window.location.href = '/dashboard';
      } else {
        window.location.reload();
      }
    }
  };

  decideShouldShowLoginModal = () => {
    const { location } = this.props;
    if (location.search.includes('showSignupPopup')) {
      this.setState({ showAffiliateLoginModal: true });
      LoginModalStore.setShouldShowModal(true);
    }
  };

  componentDidMount() {
    this.decideShouldShowLoginModal();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    const { showMobileHeader } = this.props;
    if (showMobileHeader && this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
      this.props.toggleMobileHeader();
    }
  }

  render() {
    const {
      isMobile,
      showMobileHeader,
      alreadyMember,
      isAnchor,
      isAnchorPortal,
      userPremiumExpired,
      user,
      activeCampaign,
      hideHeaderLoginButton,
      isAffiliatePage,
      location,
      toggleMobileHeader,
    } = this.props;
    const { showAffiliateLoginModal } = this.state;
    if (isAnchorPortal) {
      return null;
    }

    return (
      <>
        <div
          className={classnames(
            'collapse',
            'navbar-collapse', {
            'text-center': ((!isAnchorPortal && isMobile) || !isMobile),
            'in': isMobile || isAffiliatePage,
            'show': showMobileHeader,
          })
          }
          id="mobile-header-nav"
          ref={this.wrapperRef}
        >
          <ul className={`nav navbar-nav navbar-left ${isAnchorPortal && 'anchor-portal-navbar'}`}>
            <InternalHeaderLinks
              isAnchor={isAnchor}
              isAnchorPortal={isAnchorPortal}
              isLoggedIn={user.authenticated}
              toggleMobileHeader={toggleMobileHeader}
              isMobile={isMobile}
              user={user}
            />
          </ul>
          {!isNudeDomain && !isAnchorPortal && (
            <ul className="nav navbar-nav navbar-right">
              {user.authenticated && !isAffiliatePage ? (
                <>
                  {userPremiumExpired && !isMobile && activeCampaign && activeCampaign.hasTimer && activeCampaign.secondsLeft &&
                    <li className="navbar-login-link navbar-countdown">
                      <CountdownTimerComponent activeCampaign={activeCampaign} />
                    </li>
                  }
                  <Auth
                    location={location}
                    isAnchor={isAnchor}
                    showMobileHeader={showMobileHeader}
                    user={user}
                    userPremiumExpired={userPremiumExpired}
                  />
                </>
              ) : (
                <>
                  {!isMobile && activeCampaign && activeCampaign.hasTimer && activeCampaign.secondsLeft &&
                    <li className="navbar-login-link navbar-countdown">
                      <CountdownTimerComponent activeCampaign={activeCampaign} />
                    </li>
                  }
                  <Unauth
                    alreadyMember={alreadyMember}
                    hideHeaderLoginButton={hideHeaderLoginButton}
                    isAffiliatePage={isAffiliatePage}
                    openLoginModal={this.openLoginModal}
                  />
                </>
              )}
            </ul>
          )}
        </div>
        <AffiliateSubscriptionModal
          location={location}
          show={LoginModalStore.shouldShowModal && showAffiliateLoginModal}
          showAffiliateLoginModal={showAffiliateLoginModal}
          user={user}
        />
        {LoginModalStore.shouldShowModal && !showAffiliateLoginModal &&
          <React.Suspense fallback={null}>
            <LoginModal
              closeModal={this.closeModal}
              show={LoginModalStore.shouldShowModal && !showAffiliateLoginModal}
              showAffiliateLoginModal={showAffiliateLoginModal}
              user={user}
            />
          </React.Suspense>}
      </>
    );

  }
}

export default MobileHeaderNav;
