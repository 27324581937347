import socialImg from '../assets/dashboard-icons/social.png';
import NNHomeImg from '../assets/dashboard-icons/NN-Menu.png';
import SignOutImg from '../assets/dashboard-icons/sign-out.png';
import Messages from '../assets/dashboard-icons/mesages.png';
import newImg from '../assets/new.png';


export const INTERNAL_HEADER_LINKS = [
  {
    id: 1,
    title: 'Anchors',
    href: '/naked-news-anchors',
  },
  {
    id: 2,
    title: 'Auditions',
    href: '/auditions',
  },
  {
    id: 3,
    title: 'Fan Zone',
    href: '/fan-zone',
  },
  {
    id: 4,
    title: 'Archives',
    href: '/archives',
  },
  {
    id: 5,
    title: 'Fan Store',
    href: 'https://shop.nakednews.com/',
    loggedInUri: '?option=oauthredirect&app_name=nnstore',
    target: '_blank',
  },
  {
    id: 6,
    title: 'Naked News Live',
    href: '/live?filter=fanzone',
  },
  {
    id: 8,
    title: 'Clip Store',
    href: '/clip-store',
  },
  {
    id: 9,
    title: 'March Madness Contest',
    href: '/2024-march-madness-contest-rules',
    icon: newImg,
    absoluteIcon: true,
  },
  {
    id: 9,
    title: 'Blog',
    href: '/news',
  },
];

export const FREEMIUM_USER_HEADER_LINKS = [
  {
    id: 1,
    title: 'Anchors',
    href: '/naked-news-anchors',
  },
  {
    id: 2,
    title: 'Auditions',
    href: '/auditions',
  },
  {
    id: 3,
    title: 'Fan Zone',
    href: '/fan-zone',
  },
  {
    id: 4,
    title: 'Archives',
    href: '/archives',
  },
  {
    id: 5,
    title: 'Fan Store',
    href: 'https://shop.nakednews.com/',
    loggedInUri: '?option=oauthredirect&app_name=nnstore',
    target: '_blank',
  },
  {
    id: 6,
    title: 'Naked News Live',
    href: '/live?filter=fanzone',
  },
  {
    id: 8,
    title: 'Clip Store',
    href: '/clip-store',
  },
  {
    id: 9,
    title: 'Free Segments',
    href: '/free-segments',
  },
  {
    id: 10,
    title: 'March Madness Contest',
    href: '/2024-march-madness-contest-rules',
    icon: newImg,
    absoluteIcon: true,
  },
  {
    id: 9,
    title: 'Blog',
    href: '/news',
  },
];

export const LOGEED_IN_HEADER_LINKS = [
  {
    id: 1,
    title: 'Today\'s Show',
    href: '/home',
  },
  {
    id: 2,
    title: 'Anchors',
    href: '/naked-news-anchors',
  },
  {
    id: 3,
    title: 'Auditions',
    href: '/auditions',
  },
  {
    id: 4,
    title: 'Fan Zone',
    href: '/fan-zone',
  },
  {
    id: 5,
    title: 'Archives',
    href: '/archives',
  },
  // {
  //     id: 7,
  //     title: 'Live Cams',
  //     href: 'https://www.cam4.com/female?act=naked_news',
  //     target: '_blank'
  // },
  {
    id: 8,
    title: 'Fan Store',
    href: 'https://shop.nakednews.com/',
    loggedInUri: '?option=oauthredirect&app_name=nnstore',
    target: '_blank',
  },
  {
    id: 9,
    title: 'Naked News Live',
    href: '/live?filter=fanzone',
    /* icon: newImg,
        absoluteIcon: true*/
  },
  {
    id: 10,
    title: 'Clip Store',
    href: '/clip-store',
  },
  {
    id: 8,
    title: 'March Madness Contest',
    href: '/2024-march-madness-contest-rules',
    icon: newImg,
    absoluteIcon: true,
  },
  {
    id: 9,
    title: 'Blog',
    href: '/news',
  },
];

export const ANCHOR_HEADER_LINKS = [
  {
    id: 1,
    title: 'Today\'s Show',
    href: '/home',
  },
  {
    id: 2,
    title: 'Anchors',
    href: '/naked-news-anchors',
  },
  {
    id: 3,
    title: 'Auditions',
    href: '/auditions',
  },
  {
    id: 4,
    title: 'Fan Zone',
    href: '/fan-zone',
  },
  {
    id: 5,
    title: 'Archives',
    href: '/archives',
  },
  {
    id: 9,
    title: 'Naked News Live',
    href: '/live?filter=fanzone',
  },
  {
    id: 6,
    title: 'Dashboard',
    href: '/dashboard',
  },
  {
    id: 10,
    title: 'Clip Store',
    href: '/clip-store',
  },
  // {
  //     id: 7,
  //     title: 'Go Live',
  //     href: '/dashboard/broadcast',
  //     target: '_self'
  // }
];

export const ANCHOR_PORTAL_HEADER_LINKS = [
  // {
  //     id: 1,
  //     icon: goLiveImg,
  //     title: 'Go Live',
  //     href: '/dashboard/broadcast',
  //     target: '_self'
  // },
  {
    id: 4,
    icon: socialImg,
    title: 'Social Media Settings',
    href: '/dashboard/social-media-settings',
  },
  {
    id: 7,
    icon: Messages,
    title: 'Messages',
    href: '/dashboard/private-chat',
  },
  {
    id: 8,
    icon: NNHomeImg,
    title: 'Naked News Home',
    href: '/',
  },
  {
    id: 9,
    icon: SignOutImg,
    title: 'Sign Out',
    button: true,
  },
];

export const NUDE_NEWS_LINKS = [
  {
    id: 1,
    title: 'Current Program',
    href: '/home',
  },
  {
    id: 2,
    title: 'Blog',
    href: '/blog',
  },
  {
    id: 3,
    title: 'Anchors',
    href: '/naked-news-anchors',
  },
  {
    id: 4,
    title: 'Archives',
    href: '/archives',
  },
];
