import http from '../../../../services/http';
import {
  GLOBAL_PRESENCE_TOKEN_URL,
  OPENED_CONVERSATIONS,
  ANCHOR_FANS_LIST,
  CONVERSATION_ACCESS_TOKEN,
  LIST_OF_ANCHORS_MEMBER_SUBSCRIBED_TO,
  LOAD_STARTED_CONVERSATIONS_FANS,
} from '../../../../constants/apiUrls/apiUrls';
import * as messagesUtils from '../../../../utils/messaging';
import forResponse from '../../../../utils/forResponse';


const REQ_NUMBER_OF_MESSAGES_AFTER = 10;
const REQ_NUMBER_OF_MESSAGES_BEFORE = 15;

export const getGlobalPresenceToken = () => forResponse(
  http.post(GLOBAL_PRESENCE_TOKEN_URL)
);

export const getStartedConversations = () => forResponse(
  http.get(OPENED_CONVERSATIONS)
);

export const loadStartedConversationsForFans = () => forResponse(
  http.get(LOAD_STARTED_CONVERSATIONS_FANS)
);

export const getListOfAnchorsMemberSubscribedTo = (id) => {
  const url = LIST_OF_ANCHORS_MEMBER_SUBSCRIBED_TO.replace('{id}', id);

  return forResponse(
    http.get(url)
  );
};

export const getFansList = (id) => {
  const url = ANCHOR_FANS_LIST.replace('{id}', id);

  return forResponse(
    http.get(url)
  );
};

export const createConversation = (data) => forResponse(
  http.post(OPENED_CONVERSATIONS, data)
);

export const getConversation = (partner) => forResponse(
  http.get(OPENED_CONVERSATIONS, { params: { partner } })
);

export const getConversationAccessToken = (name) => {
  const url = CONVERSATION_ACCESS_TOKEN.replace('{name}', name);

  return forResponse(
    http.post(url)
  );
};

export const loadAroundMessages = (method, timestamp, before = REQ_NUMBER_OF_MESSAGES_BEFORE, after = REQ_NUMBER_OF_MESSAGES_AFTER) => new Promise((resolve, reject) => {
  method(timestamp, before, after, (error, messages) => {
    if (!error) {
      if (messages) {
        resolve({ messages });
      }
    } else {
      reject(error);
    }
  });
});

export const loadMessages = (methods, timestamp) => new Promise(async(resolve) => {
  const nextResults = await messagesUtils.loadNextMessages(methods.next, timestamp, 0);
  const prevResults = await messagesUtils.loadPreviousMessages(methods.prev, timestamp + 1, 0);
  if (prevResults.messages && nextResults.messages) {
    resolve({ messages: [...prevResults.messages.reverse(), ...nextResults.messages] });
  }
});
