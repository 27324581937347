import { action, observable } from 'mobx';

import { PROCESSOR_IDS, PROCESSOR_OPT_STATUS } from '../constants/processors';
import { GET_PROCESSOR_TO_USE } from '../constants/apiUrls/apiUrls';
import callApi from '../utils/callApi';
import NotificationStore from './NotificationStore';


class PaymentFallbackStore {
  @observable processorToUse = PROCESSOR_IDS.EPOCH;

  getProcessorForUser = (user) => {
    const username = sessionStorage.getItem('username') || user?.user?.username;
    const token = sessionStorage.getItem('token') || user?.token;

    return callApi(GET_PROCESSOR_TO_USE, 'GET', null, null, { username, Authorization: `Bearer ${token}` });
  };

  getFallbackProcessorToUse = (processorId) => {
    if (parseInt(processorId, 10) === PROCESSOR_IDS.SEGPAY) {
      return PROCESSOR_IDS.EPOCH;
    }

    if (parseInt(processorId, 10) === PROCESSOR_IDS.EPOCH) {
      return PROCESSOR_IDS.SEGPAY;
    }

    return PROCESSOR_IDS.SEGPAY;
  };

  shouldUseAlternativePaymentMethod = (processorId) => {
    if (processorId === PROCESSOR_IDS.SEGPAY) {
      return false;
    }

    return true;
  };

  setProcessorForUser = (user, processorId) => {
    this.processorToUse = processorId;
    if (!user?.authenticated) {
      return;
    }
    const useAlternativePaymentProcessor = this.shouldUseAlternativePaymentMethod(processorId);
    const username = sessionStorage.getItem('username') || user?.user?.username;
    const token = sessionStorage.getItem('token') || user?.token;
    if (!username && !token) {
      return;
    }
    callApi(
      GET_PROCESSOR_TO_USE,
      'POST',
      null,
      { useAlternativePaymentProcessor },
      { username, Authorization: `Bearer ${token}` }
    );
  };

  @action checkForPaymentFailure = (urlParams, user) => {
    const opStatus = urlParams.get('opStatus');
    const processorId = urlParams.get('processorId');
    if (opStatus && opStatus === PROCESSOR_OPT_STATUS.FAILED) {
      const processorToUse = this.getFallbackProcessorToUse(processorId);
      NotificationStore.error('There was a problem with payment. Please try again.');
      this.setProcessorForUser(user, processorToUse);
    }
  };

  @action changeProcessorForUser = (user, processorId) => {
    const processorToUse = this.getFallbackProcessorToUse(processorId);
    this.setProcessorForUser(user, processorToUse);
  };

  getProcessorToUse = (shouldUseAlternativePaymentMethod) => {
    if (shouldUseAlternativePaymentMethod) {
      return PROCESSOR_IDS.SEGPAY;
    }

    return PROCESSOR_IDS.EPOCH;
  }

  getProcessorToUseForUser = async (user) => {
    if (!user?.authenticated) {
      return this.processorToUse;
    }

    const { data, error } = await this.getProcessorForUser(user);
    if (!error) {
      return this.getProcessorToUse(data.useAlternativePaymentProcessor);
    }
  }
}

export default new PaymentFallbackStore();
