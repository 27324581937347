import {
  INTERNAL_HEADER_LINKS,
  LOGEED_IN_HEADER_LINKS,
  ANCHOR_HEADER_LINKS,
  ANCHOR_PORTAL_HEADER_LINKS,
  NUDE_NEWS_LINKS,
  FREEMIUM_USER_HEADER_LINKS,
} from '../../../constants/internalHeaderLinks';
import { isNudeDomain } from '../../../utils/helpers';

/**
 * @param {object} user - user context object
 * @param {boolean} isAnchorPortal - is user currently on anchor portal pages
 * @param {boolean} isLoggedIn - is user logged in
 * @param {boolean} isAnchor - is currently logged in user of type anchor
 * @param {boolean} chatAvailable - indicates whether user has chatting features permitted
 */
export default (user, isAnchorPortal, isLoggedIn, isAnchor, chatAvailable) => {
  let links = [];
  let loggedInHeaderLinks;
  const canSeeCalendar = user ? user.features.includes('CALENDAR_2020') : false;
  const canSeeShop = user ? user.features.includes('SHOW_SHOP_LINK') : false;
  const canSeeSummerPack = user ? user.features.includes('SUMMER_PACK_2021') : false;
  const canSeeMarchMadnessLink = user?.features?.includes('MARCH_MADNESS_2024');

  if (!chatAvailable) {
    loggedInHeaderLinks = LOGEED_IN_HEADER_LINKS.filter((link) => link.title !== 'Chat');
  } else {
    loggedInHeaderLinks = LOGEED_IN_HEADER_LINKS;
  }

  if (isLoggedIn) {
    if (isNudeDomain) {
      links = NUDE_NEWS_LINKS;
    } else if (isAnchor) {
      links = isAnchorPortal ? ANCHOR_PORTAL_HEADER_LINKS : ANCHOR_HEADER_LINKS;
    } else {
      const isWhitelisted = user ? user.features.includes('ANCHOR_PORTAL') : false;
      links = loggedInHeaderLinks.filter((l) => l.onlyAnchorPortalFeatureEnabled ? isWhitelisted : true);
    }
  } else if (!isNudeDomain) {
    links = INTERNAL_HEADER_LINKS;
  }
  if (user?.user?.account?.freemiumUser && !user?.user?.activeSubscription) {
    links = FREEMIUM_USER_HEADER_LINKS;
  }
  if (!canSeeCalendar || canSeeSummerPack) {
    links = links.filter((l) => l.title !== '2021 Calendar');
  }
  if (!canSeeShop) {
    links = links.filter((l) => l.title !== 'Fan Store');
  }
  if (!canSeeMarchMadnessLink) {
    links = links.filter((link) => link.title !== 'March Madness Contest');
  }
  if (!canSeeSummerPack) {
    links = links.filter((l) => l.title !== 'Summer Pack');
  }

  if (isLoggedIn) {
    links = links.map((item) => {
      if (item.loggedInUri) {
        return { ...item, href: item.href + item.loggedInUri };
      }

      return item;
    });
  }


  return links;
};
