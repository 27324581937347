import { action, observable } from 'mobx';

class LoginModalStore {
  @observable shouldShowModal = false;

  @action setShouldShowModal = value => {
    this.shouldShowModal = value;
  }
}

export default new LoginModalStore();
