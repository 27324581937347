import React from 'react';
import { Link } from 'react-router-dom';

import { doLogout } from '../../../utils/auth';
import { NakedLink } from '../../../utils/NakedLink';
import styles from '../css/InternalHeaderLinks.module.css';

import buildLinks from './buildLinks';
import MobileChatLink from './MobileChatLink';


const InternalHeaderLinks = ({
  isLoggedIn,
  isMobile,
  isAnchor,
  isAnchorPortal,
  user,
  toggleMobileHeader,
}) => {
  const chatAvailable = user.user ? user.user.account.anchorSubscriptions.length > 0 : null;
  const links = buildLinks(user, isAnchorPortal, isLoggedIn, isAnchor, chatAvailable);
  const logout = React.useCallback((event) => {
    event.preventDefault();
    doLogout();
  }, []);

  const handleLinkClick = React.useCallback(() => {
    if (isMobile) {
      toggleMobileHeader();
    }
  }, [isMobile, toggleMobileHeader]);

  return (
    <>
      {links.map((link) => (
        <li key={link.id} className={styles.navLink}>
          <NakedLink
            className={window.location.pathname === link.href ? styles.active : styles.anchorLink}
            target={link.target ? link.target : ''}
            to={link.href}
            onClick={handleLinkClick}
          >
            {link.icon && (
              <img
                alt={`${link.title} icon`}
                className={link.absoluteIcon ? 'nav-icon-absolute' : 'anchor-portal-nav-icon'}
                src={link.icon}
              />
            )}
            {link.title}
          </NakedLink>
        </li>
      ))}
      {isMobile && isLoggedIn && !isAnchorPortal && (
        <>
          {chatAvailable && <MobileChatLink />}
          <li className={styles.navLink}>
            <Link to="/account" onClick={handleLinkClick}>Account Settings</Link>
          </li>
          <li className={styles.navLink}>
            <a href="#" onClick={logout}>Logout</a>
          </li>
        </>
      )}
    </>
  );
};

export default InternalHeaderLinks;
