import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'simple-react-modal';

import NakedNewsLogo from '../../assets/logo-naked-news.png';
import '../../pages/AffiliateProgram/AffiliateProgram.css';
import AnchorsOne from '../../assets/Affiliate/anchors_signup.gif';
import AnchorsTwo from '../../assets/Affiliate/Precomp girls groupled.jpg';
import LoginModalStore from '../../store/LoginModalStore';

import { CheckTwoTone } from '@material-ui/icons';

import { CHECK_USER_EXISTS } from '../../constants/apiUrls/apiUrls';
import callApi from '../../utils/callApi';

import EmailValidationError from './EmailValidation/EmailValidationError';


function AffiliateSubscriptionModal(props) {
  const { show, user, showAffiliateLoginModal } = props;
  const [email, setEmail] = useState('');
  const [imageToUse, setImageToUse] = useState(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('showSignupPopup') === '1') {
      setImageToUse(AnchorsOne);
    } else {
      setImageToUse(AnchorsTwo);
    }
  }, []);

  const closeModal = () => {
    LoginModalStore.setShouldShowModal(false);
  };

  const onInputChange = useCallback((e) => {
    const { value } = e.target;
    setEmail(value);
  }, []);

  const handleSignUpClick = useCallback(async(e) => {
    e.preventDefault();
    const validEmail = await onSubmit();
    if (validEmail.data.status === 'ALREADY_IN_USE') {
      setEmailErrorMessage('You already have an account please login!');

      return;
    }
    if (validEmail.data.status === 'NOT_OK') {
      setEmailErrorMessage('Please enter a valid email!');

      return;
    }
    if (validEmail.error) {
      setEmailErrorMessage('There was an error. Please try again!');

      return;
    }
    setEmailErrorMessage('');
    window.location.href = `/account/purchase?email=${email}`;
  }, [email, onSubmit]);

  function onSubmit() {
    setEmailErrorMessage('');
    const URL = CHECK_USER_EXISTS.replace('{userEmailID}', `${email}`);

    return callApi(URL);
  }

  if (!showAffiliateLoginModal || user.authenticated) {
    return null;
  }

  return (
    <Modal className="affiliate-sign-up-modal" closeOnOuterClick={true} show={show} onClose={closeModal} >
      <div className="affiliate-modal-container">
        <div className="affiliate-modal-content flex">
          <form className="affiliate-sign-up-form flex flex-col">
            <div className="input-wrapper flex flex-col">
              <img alt="nakedNEWS" className="sign-up-logo" src={NakedNewsLogo} />
              <div className="modal-bullet-points-title">
                Get Complete Access to:
              </div>
              <ul className="list-unstyled modal-bullet-points darker">
                <li>
                  <CheckTwoTone />&nbsp;
                  All New Shows in Stunning HD
                </li>
                <li>
                  <CheckTwoTone />&nbsp;
                  6 Hot New Episodes per Week
                </li>
                <li>
                  <CheckTwoTone />&nbsp;
                  On-Demand Access to Our Archives
                </li>
                <li>
                  <CheckTwoTone />&nbsp;
                  Fully Naked Amateur Auditions
                </li>
              </ul>
              <input placeholder="Enter your email address" type="text" value={email} onChange={onInputChange} />
              <EmailValidationError
                authenticated={user?.authenticated}
                emailErrorMessage={emailErrorMessage}
              />
              <button className="btn btn-red" onClick={handleSignUpClick}>SIGN UP</button>
              <small>By creating an account, you agree to our&nbsp;
                <a href="/terms_of_service">Terms & Conditions</a>&nbsp;
                and&nbsp;
                <a href="/privacy_policy">Privacy Policy</a>
              </small>
            </div>
            <div className="member-login-section flex items-center">
              <small className="white text-center">Already have an account? <a href="/account/login">Sign in here.</a></small>
            </div>
          </form>
          <img alt="naked-news-anchors" className="anchor-right-img" src={imageToUse} />
        </div>

      </div>
    </Modal>
  );
}

export default AffiliateSubscriptionModal;
