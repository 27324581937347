import React from 'react';
import { observer } from 'mobx-react';


import Notifications from '../Notifications/Notifications';
import 'url-search-params-polyfill';
import { UserContext, RootStoreContext } from '../../context';
import { BlogProvider } from '../../hooks/useBlog';
import { overrideStatusResponse } from '../../utils/overrideStatusResponse';
import GlobalNotification from '../GlobalNotification/GlobalNotification';
import Routes from '../Routes/Routes';
import FullLoader from '../Loaders/FullLoader';

import {
  checkStatus,
  checkStatusTempted,
  getActiveCampaign,
  getAnchorPortalCampaign,
  initializeGlobalSession,
} from './services';
import CampaignStore from '../../store/CampaignStore';
import PaymentFallbackStore from '../../store/PaymentFallbackStore';
import { getCookie } from '../../utils/helpers';
import { EXPIRY_TIMES_IN_MS, withCacheStorage } from '../../utils/withCache';
import callApi from '../../utils/callApi';
import { SSO_REDIRECT_SESSION, sso_session_redirect_url } from '../../constants/apiUrls/apiUrls';


@observer
class App extends React.Component {

  static contextType = RootStoreContext;

  state = {
    user: {
      userDetails: {},
      token: '',
      features: [],
    },
    isLoaded: false,
    showCookieConsent: false,
    showPremiumUpgradeBar: false,
    userPremiumExpired: false,
    activeCampaigns: [],
    anchorPortalCampaign: {},
  };

  handleSSODataForward = (ssoRedirect) => {
    callApi(SSO_REDIRECT_SESSION, 'get', { 'redirect_sso_session': ssoRedirect }).then((res) => {
      window.location.href = '/';
    })
  }

  doSSOLogin = () => {
    if (getCookie('sso_cookie')) {
      console.log('returned')
      return;
    }
    const homeUrl = process.env.REACT_APP_NAKEDNEWS_UI || "http://localhost:3000/";
    const rediretUrl = sso_session_redirect_url.replace("{redirectUri}", homeUrl);
    var date = new Date();
    date.setTime(date.getTime() + (60 * 1000));
    document.cookie = `sso_cookie=true; expires=${date}; path=/`;
    console.log(document.cookie)
    window.location.href = rediretUrl;
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const affiliateCode = urlParams.get('_atc');
    const ssoRedirect = urlParams.get('redirect_sso_session');



    if (affiliateCode) {
      document.cookie = `_atc=${affiliateCode}; expires=0; path=/`;
    }
    this.handleAffiliateTrackingParameter(urlParams);
    const [statusResponse, campaignResponse, anchorCampaignResponse] = await withCacheStorage([
      { noCache: true, fn: checkStatus },
      { expiryTime: EXPIRY_TIMES_IN_MS['15_MINS'], cacheKey: 'campaignActive', fn: getActiveCampaign },
      { expiryTime: EXPIRY_TIMES_IN_MS['15_MINS'], cacheKey: 'campaignAnchorPortal', fn: getAnchorPortalCampaign },
    ]);

    const { data: { data: anchorPortalCampaign } } = anchorCampaignResponse;
    const activeCampaigns = campaignResponse?.data?.data;
    const { error, data } = statusResponse;

    if (data?.data?.authenticated) {
      sessionStorage.username = data?.data?.user?.username;
      sessionStorage.token = data?.data?.user?.token;
    }
    const temptedStatusResponse = await checkStatusTempted();
    const ssoLoginIsEnabled = data?.data?.features?.includes('SSO_ENABLED');
    if (ssoRedirect?.length > 0) {
      this.handleSSODataForward(ssoRedirect);
    }

    CampaignStore.setCurrentCampaign(data?.data, activeCampaigns);
    PaymentFallbackStore.checkForPaymentFailure(urlParams, data?.data);
    const userPremiumExpired = data.data.authenticated && !data.data.user.activeSubscription;
    if (!error) {
      overrideStatusResponse(data, temptedStatusResponse);
    }
    this.setAppState(userPremiumExpired, data?.data, activeCampaigns, anchorPortalCampaign);
    this.initializeGlobalPresence(data?.data);
  }

  handleAffiliateTrackingParameter = (urlParams) => {
    const actParameter = urlParams.get('act');
    if (getCookie('affiliateCode')) {
      return;
    }
    if (actParameter) {
      const currentDate = new Date();
      const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
      const expiryDate = new Date(currentDate.getTime() + thirtyDaysInMilliseconds);
      const expires = expiryDate.toUTCString();
      document.cookie = `affiliateCode=${actParameter}; expires=${expires}; path=/`;
    }
  };

  setAppState = (userPremiumExpired, userData, activeCampaigns, anchorPortalCampaign) => {
    this.setState({
      isLoaded: true,
      activeCampaigns,
      user: userData,
      userPremiumExpired,
      anchorPortalCampaign,
      showCookieConsent: !document.cookie.includes('notify_cookie_usage=true'),
    });
    this.context.loggedInUser = userData;
  };

  initializeGlobalPresence = async (data) => {
    const globalPresenceRes = await initializeGlobalSession(data, this.context);
    if (!globalPresenceRes?.data) {
      console.error('Error initializing global presence');
    }
  };

  closePremiumUpgradeBar = () => {
    this.setState({ showPremiumUpgradeBar: false });
  };

  acceptCookies = (event) => {
    event.preventDefault();
    document.cookie = 'notify_cookie_usage=true;max-age=2592000';
    this.setState({ showCookieConsent: false });
  };

  render() {
    const {
      user,
      showPremiumUpgradeBar,
      userPremiumExpired,
      activeCampaigns,
      anchorPortalCampaign,
    } = this.state;

    const notificationsAvailable = !(user?.user && user?.user?.account?.anchor);

    if (!this.state.isLoaded) {
      return <FullLoader />;
    }

    return (
      <>
        <UserContext.Provider value={user}>
          <BlogProvider>
            {notificationsAvailable && <Notifications user={user} />}
            <Routes
              activeCampaigns={activeCampaigns}
              anchorPortalCampaign={anchorPortalCampaign}
              closePremiumUpgradeBar={this.closePremiumUpgradeBar}
              showPremiumUpgradeBar={showPremiumUpgradeBar}
              userPremiumExpired={userPremiumExpired}
            />
            <GlobalNotification />
          </BlogProvider>
        </UserContext.Provider>
      </>
    );
  }
}

export default App;
