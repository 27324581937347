export const overrideStatusResponse = async (data, temptedData) => {
  const { data: dataTempted, error: errorTempted } = temptedData;

  if (!errorTempted) {
    if (data && data.data && data.data.user && data.data.user.account) {
      if (dataTempted && dataTempted.data && dataTempted.data.user && dataTempted.data.user.account) {
        if (dataTempted.data.user.account.anchorSubscriptions) {
          data.data.user.account.anchorSubscriptions = dataTempted.data.user.account.anchorSubscriptions;
        }
        if (dataTempted.data.user.account.nickname) {
          data.data.user.account.nickname = dataTempted.data.user.account.nickname;
        }
        if (dataTempted.data.user.account.nicknameUpdateCounter) {
          data.data.user.account.nicknameUpdateCounter = dataTempted.data.user.account.nicknameUpdateCounter;
        }
        if (dataTempted.data.user.account.affiliateCode) {
          data.data.user.account.affiliateCode = dataTempted.data.user.account.affiliateCode;
        }
        if (dataTempted.data.user.account.id) {
          data.data.user.account.temptedId = dataTempted.data.user.account.id;
        }
      }
    }

    if (dataTempted?.data?.features) {
      data.data.temptedFeatures = dataTempted.data.features;
    }
  } else {
    console.error(errorTempted);
  }
};
