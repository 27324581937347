import React from 'react';
import { when } from 'mobx';
import { observer } from 'mobx-react';
import * as Sentry from '@sentry/browser';
import classnames from 'classnames';

import HeaderLogoAndDropDown from '../headerComponents/HeaderLogoAndDropDown';
import CookieConsent from '../generalComponents/CookieConsent';
import MobileHeaderNav from '../headerComponents/MobileHeaderNav/MobileHeaderNav';
import HeaderNav from '../headerComponents/HeaderNav';
import UpgradeAccountHeader from '../UpgradeAccountHeader/UpgradeAccountHeader';
import { UserContext } from '../../context';
import SaasLogger from '../../services/SaasLogger';
import AnchorStore from '../../store/AnchorStore';
import { getCookie } from '../../utils/helpers';
import { CALENDAR_DISPLAY } from '../../constants/modals';
import EmailWhitelistMessage from '../generalComponents/EmailWhitelistMessage';
import FullLoader from '../Loaders/FullLoader';
import { getModalsData } from '../App/services';
import '../../pages/Home/css/GuestNavMenu.css';
import { LIST_OF_PATHS_TO_HIDE_DEFAULT_HEADER_AND_FOOTER } from '../../constants/Constants';


const CalendarPromoModal = React.lazy(() => import('../CalendarPromoModal/CalendarPromoModal'));
const AnchorDrawerNavigation = React.lazy(() => import('../headerComponents/AnchorDrawerNavigation'));
const AnchorPortalHeader = React.lazy(() => import('../headerComponents/AnchorPortalHeader'));


@observer
class Header extends React.Component {

  static contextType = UserContext;

  state = {
    showMobileHeader: false,
    isMobile: false,
    showAnchorMobileHeader: false,
    showCookieConsent: !document.cookie.includes('notify_cookie_usage=true'),
    showCalendarModal: false,
    showEmailWhitelist: !document.cookie.includes('show_email_whitelist=true'),
    modalsData: [],
  };

  toggleMobileHeader = () => {
    const { showMobileHeader } = this.state;

    this.setState({ showMobileHeader: !showMobileHeader });
  };

  openAnchorMobileHeader = () => this.setState({ showAnchorMobileHeader: true });
  closeAnchorMobileHeader = () => this.setState({ showAnchorMobileHeader: false });

  closeCalendarModal = () => this.setState({ showCalendarModal: false });

  fetchModalsData = async () => {
    const { authenticated } = this.context;
    if (authenticated) {
      const { data: modalsResponse } = await getModalsData();
      this.setState({ modalsData: modalsResponse.data });
    }
  };

  componentDidMount() {
    this.setState({ isMobile: window.innerWidth <= 991 });
    this.setUserData();
    this.fetchModalsData();
    window.addEventListener('resize', () => {
      if (this.state.isMobile !== (window.innerWidth <= 991)) {
        this.setState({ isMobile: window.innerWidth <= 991 });
      }
    }, false);
    when(
      () => AnchorStore.isAnchorLive !== undefined,
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ showCalendarModal: false });
      clearTimeout(this.timeout);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  handleCalendarPromoModal = () => {
    const user = this.context;
    if (!user?.features.includes('CALENDAR_2023') || !user?.features.includes('SHOW_SHOP_LINK')) {
      return;
    }
    this.timeout = setTimeout(() => {
      const { modalsData } = this.state;
      const { location: { pathname } } = this.props;
      const modal = modalsData.find((el) => el.modalType === CALENDAR_DISPLAY);
      const exceptions = ['/account/purchase', '/calendar-purchase', '/calendar-checkout', '/oauth2/login'];
      const calendarModalShown = sessionStorage.getItem('CALENDAR_MODAL_SHOWN');
      const shouldntShowModal =
        exceptions.includes(pathname) ||
        (/(^\/naked-news-anchor-)/.test(pathname) && (AnchorStore.isAnchorLive || AnchorStore.isAnchorLive === undefined)) ||
        (!user.authenticated && getCookie('CALENDAR_DISPLAY_2023')) ||
        (user.authenticated && modal && !modal.showModal)
        || calendarModalShown;


      if (!shouldntShowModal) {
        this.setState({ showCalendarModal: true });
        sessionStorage.setItem('CALENDAR_MODAL_SHOWN', true);
      }
    }, 5000);
  };

  setUserData = () => {
    const user = this.context;
    if (!user.authenticated) {
      return;
    }
    const userData = {
      id: user.user.account.id,
      username: user.user.username,
    };
    if (user.authenticated) {
      Sentry.configureScope((scope) => {
        scope.setUser(userData);
      });
      SaasLogger.setUserData(userData);
    }
  };

  isAnchor = () => {
    const user = this.context;
    let isAnchor = false;
    if (user && user.user && user.user.username) {
      isAnchor = user.user.account.anchor;
    }

    return isAnchor;
  };

  acceptCookies = (event) => {
    event.preventDefault();
    document.cookie = 'notify_cookie_usage=true;max-age=2592000';
    this.setState({ showCookieConsent: false });
  };

  dontShow = () => {
    document.cookie = 'show_email_whitelist=true;max-age=2592000';
    this.removeEmailWhitelistMessage();
  };

  removeEmailWhitelistMessage = () => {
    this.setState({ showEmailWhitelist: false });
  };

  closeMobileHeader = () => {
    this.setState({ showMobileHeader: false });
  };

  render() {
    const {
      showPremiumUpgradeBar,
      closePremiumUpgradeBar,
      userPremiumExpired,
      isAnchorPortal,
      location,
      activeCampaign,
      isAffiliatePage,
      isGuestHomePage,
    } = this.props;
    const { location: { pathname } } = this.props;
    const hideHeaderLoginButton = pathname === '/oauth2/login';
    const user = this.context;
    const {
      showMobileHeader,
      isMobile,
      showAnchorMobileHeader,
      showCookieConsent,
      showCalendarModal,
      showEmailWhitelist,
    } = this.state;
    const alreadyMember = document.cookie.includes('_membership=1');
    if (LIST_OF_PATHS_TO_HIDE_DEFAULT_HEADER_AND_FOOTER.includes(location?.pathname) ||
      (location.pathname === '/dashboard/broadcast' && isMobile)) {
      return null;
    }

    const isFan = user.authenticated && !user.user.account.anchor;

    return (
      <>
        {showEmailWhitelist && isFan && (
          <EmailWhitelistMessage
            dontShow={this.dontShow}
            removeEmailWhitelistMessage={this.removeEmailWhitelistMessage}
          />
        )}
        {showCookieConsent && <CookieConsent onAccept={this.acceptCookies} />}
        {showPremiumUpgradeBar && (
          <UpgradeAccountHeader
            closePremiumUpgradeBar={closePremiumUpgradeBar}
            isMobile={isMobile}
          />
        )}
        {showCalendarModal && <React.Suspense fallback={null}>
          <CalendarPromoModal
            closeCalendarModal={this.closeCalendarModal}
            showCalendarModal={showCalendarModal}
            user={user}
          />
        </React.Suspense>}
        <div id="navigation">
          <div
            className={classnames('navbar', 'navbar-lg', 'navbar-topnav', {
              'anchor-portal-topnav': isAnchorPortal,
              'affiliate-topnav': isAffiliatePage,
              'guest-home-nav': !isAffiliatePage,
              'guest-nav-menu': !isAffiliatePage,
            })}
            id="header-nav"
            role="navigation"
          >
            {isAnchorPortal ? (
              <React.Suspense fallback={<FullLoader />}>
                <AnchorPortalHeader handleClick={this.openAnchorMobileHeader} />
                <AnchorDrawerNavigation
                  anchor="right"
                  open={showAnchorMobileHeader}
                  onClose={this.closeAnchorMobileHeader}
                />
              </React.Suspense>
            ) : (
              <>
                <HeaderLogoAndDropDown
                  showMobileHeader={showMobileHeader}
                  activeCampaign={activeCampaign}
                  isGuestHomePage={isGuestHomePage}
                  location={location}
                  toggleMobileHeader={this.toggleMobileHeader}
                  user={user}
                  userPremiumExpired={userPremiumExpired}
                />
                <MobileHeaderNav
                  activeCampaign={activeCampaign}
                  alreadyMember={alreadyMember}
                  hideHeaderLoginButton={hideHeaderLoginButton}
                  isAffiliatePage={isAffiliatePage}
                  isAnchor={this.isAnchor()}
                  isMobile={isMobile}
                  location={this.props.location}
                  showMobileHeader={showMobileHeader}
                  toggleMobileHeader={this.closeMobileHeader}
                  user={user}
                  userPremiumExpired={userPremiumExpired}
                />
                <HeaderNav isAnchor={this.isAnchor()} user={user} />
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Header;
