import {
  parseHoursAndMinutesFromTimestamp,
  parseTimeRelativeToReference,
} from '../../../../utils/time';


export function messages() {
  if (!this.activeConversation) {
    return [];
  }

  let prevRelativeTime = null;

  return this.activeConversationMessages.map((m) => {
    const relativeTime = parseTimeRelativeToReference(m.timestamp);
    const isMine = this.myConversationMemberObject.username === m.username;
    const isAnchor = !!this.rootStore.loggedInUser.account.anchor;
    const computedMessageObject = {
      messageMetadata: m.messageMetadata,
      message: m.message,
      timestamp: m.timestamp,
      username: m.username,
      resource: m.resource,
      isMine,
      isAnchor,
      isSeen: m.timestamp === this.seenTimestamp,
      messageType: m.type,
      displayTime: parseHoursAndMinutesFromTimestamp(m.timestamp),
      dateGroup: relativeTime !== prevRelativeTime ? relativeTime : null,
      details: m.details,
      isRegularMessage: m.type === 'TEXT',
      isAudioMessage: m.type === 'AUDIO',
      isPrivateContentMessage: m.type === 'CUSTOM' && m.details?.type === 'privateContentMessage',
      isMassMessage: m.type === 'CUSTOM' && m.details?.type === 'massMessage',
      isTippingMessage: m.type === 'CUSTOM' && m.details?.type === 'tippingMessage',
      isCalendarShowNotification: m.type === 'CUSTOM' && m.details.type === 'calendarShowNotification',
      isRequestLiveShowMessage: m.type === 'CUSTOM' && m.details?.type === 'requestLiveShowMessage',
      isSmartMessage: m.type === 'CUSTOM' && m.details?.type === 'smartMessage',
      isWishCompletedMessage: m.type === 'CUSTOM' && m.details?.type === 'contributorToWishCompleted',
      isTemporaryMessagingExpiryReminder: m.type === 'CUSTOM' && m.details?.type === 'temporaryConversationExpirationWarning',
      isGeoblockingNotification: m.type === 'CUSTOM' && m.details?.type === 'geoblockingNotification',
      isPunishedNotification: m.type === 'CUSTOM' && m.details?.type === 'punishment',
      punishmentExpiresAt: m?.details?.expiresAt,
      punishmentReason: m?.details?.reason,
    };
    prevRelativeTime = relativeTime;

    return computedMessageObject;
  });
}
